import { PageLayout } from "../../components/layout/PageLayout"
import { SignupArea } from "../../features/auth/SignupArea"
import { LoginArea } from "../../features/auth/LoginArea"
import React from "react"
import { useProfile } from "../../app/api/service"
import { OAuth, OAuthSkeleton } from "../../features/auth/OAuth"
import { CutPaper } from "../../components/paper/CutPaper"
import {
  AppBar,
  Box,
  Breakpoint,
  Container,
  Grid,
  Skeleton,
  Stack,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material"
import { NavBar } from "../../components/nav/NavBar"
import { Link } from "react-router-dom"

export function OAuthLayout(props: {
  children: React.ReactNode
  maxWidth?: Breakpoint
}) {
  const theme = useTheme()

  return (
    <>
      <main
        style={{
          background: theme.palette.background.mainBackground,
          // filter: "contrast(170%) brightness(1000%)",
          height: "100vh",
          width: "100vw",
          overflowY: "scroll",
          paddingBottom: 32,
        }}
      >
        <AppBar
          elevation={0}
          variant={"outlined"}
          sx={{
            clipPath: "none",
            position: "static",
            marginBottom: 4,
            padding: 1,
            background: "transparent",
            border: "none",
          }}
        >
          <Toolbar
            sx={{
              paddingRight: 2, // keep right padding when drawer closed
              paddingLeft: 2, // keep right padding when drawer closed
              // boxShadow: `0 3px 5px 3px ${theme.palette.primary.main}`,
              overflow: "visible",
              background: "transparent",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid #AAAACC66",
                paddingBottom: 1,
                width: "100%",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Link
                  to={"/"}
                  style={{
                    textDecoration: "none",
                    color: theme.palette.primary.main,
                  }}
                >
                  <Typography
                    variant={"h4"}
                    sx={{
                      textTransform: "uppercase",
                      fontWeight: 600,
                      fontFamily: "Orbitron",
                    }}
                  >
                    Citizen ID
                  </Typography>
                </Link>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
        <Container maxWidth={"sm"}>
          <Grid container spacing={4} justifyContent={"center"}>
            {props.children}
          </Grid>
        </Container>
      </main>
    </>
  )
}

export function OAuthAuthorize() {
  const { isSuccess, isLoading } = useProfile()

  return (
    <OAuthLayout>
      {isLoading ? (
        <OAuthSkeleton />
      ) : !isSuccess ? (
        <>
          <SignupArea />
          <LoginArea />
        </>
      ) : (
        <OAuth />
      )}
    </OAuthLayout>
  )
}
