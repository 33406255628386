import React, { useState } from "react"
import { useDeleteUserMutation } from "../../app/api/service"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material"
import LoadingButton from "@mui/lab/LoadingButton"
import { Cookies } from "react-cookie"

export function AccountDeleteConfirmationDialog() {
  const [open, setOpen] = useState(false)
  const [deleteUser, { isLoading }] = useDeleteUserMutation()

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        color={"error"}
        variant={"contained"}
      >
        Delete Account
      </Button>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle id="alert-dialog-title">Delete Account</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure? You can't undo this action afterwards.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpen(false)}
            variant={"contained"}
            autoFocus
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={async () => {
              await deleteUser()
              const cookies = new Cookies(null, { path: "/" })
              cookies.remove("auth_token")
              cookies.remove("refresh_token")
              setOpen(false)
            }}
            variant={"contained"}
            color={"error"}
            loading={isLoading}
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
