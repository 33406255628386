import React, { useCallback, useEffect, useMemo, useState } from "react"
import {
  useEmailLoginMutation,
  useProfile,
  useNewUserMutation,
} from "../../app/api/service"
import { validateEmail } from "../../util/validation"
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { Visibility, VisibilityOff } from "@mui/icons-material"
import LoadingButton from "@mui/lab/LoadingButton"
import { useAlert } from "../../components/alert/AlertProvider"
import { CutPaper } from "../../components/paper/CutPaper"
import { Link } from "react-router-dom"
import { BaseResponse } from "../../types/user"

export function SignupArea() {
  const [email, setEmail] = useState<string | null>(null)
  const [password, setPassword] = useState("")
  const [show, setShow] = React.useState(false)
  const { isLoading: userIsLoading } = useProfile()

  const emailValid = useMemo(() => !email || validateEmail(email), [email])

  const [createUser, { isLoading }] = useNewUserMutation()
  const [loginEmail, { isLoading: loginIsLoading }] = useEmailLoginMutation()
  const issueAlert = useAlert()

  const signUp = useCallback(() => {
    if (!email) {
      setEmail("")
      return
    }

    createUser({
      email,
      password,
    })
      .unwrap()
      .then((_) => {
        loginEmail({ email, password, app_id: import.meta.env.VITE_APP_ID })
          .unwrap()
          .catch((e) =>
            issueAlert({
              message: "Invalid username or password",
              severity: "error",
            }),
          )
      })
      .catch((e: { status: number; data: BaseResponse<string> }) => {
        let message
        switch (e.status) {
          case 409:
            message = "There is already an account registered with this email"
            break
          default:
            message = "An unexpected error occurred"
            break
        }
        issueAlert({
          message: message,
          severity: "error",
        })
      })
  }, [createUser, email, loginEmail, password])

  return (
    <Grid item xs={12} lg={6}>
      <CutPaper>
        <Stack spacing={1} direction={"column"}>
          <Typography variant="h5">Sign Up</Typography>
          <Divider />
          <TextField
            fullWidth
            label={"Email Address"}
            placeholder={"me@example.com"}
            onChange={(event) => setEmail(event.target.value.trim())}
            value={email || ""}
            type={"email"}
            error={!emailValid}
            helperText={emailValid ? undefined : "Invalid address"}
          />
          <TextField
            fullWidth
            label={"Password"}
            placeholder={"Password"}
            type={show ? "text" : "password"}
            onChange={(event) => setPassword(event.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShow((s) => !s)}>
                    {show ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <LoadingButton
            onClick={signUp}
            disabled={!emailValid || !password.length || !email}
            loading={isLoading || loginIsLoading || userIsLoading}
          >
            Sign Up
          </LoadingButton>

          <Stack
            spacing={1}
            direction={"row"}
            sx={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography>
              Already have an account?{" "}
              <Link to={"/login"}>
                <Button variant={"text"}>Log In</Button>
              </Link>
            </Typography>
          </Stack>
        </Stack>
      </CutPaper>
    </Grid>
  )
}
