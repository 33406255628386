import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import {
  BaseResponse,
  EmailLoginBody,
  LinkResponse,
  LoginResponse,
  NewUserBody,
  TokenLoginBody,
  User,
} from "../../types/user"
import {
  Application,
  NewApplicationBody,
  NewApplicationResponse,
  UpdateApplicationBody,
} from "../../types/application"
import {
  AuthorizeBody,
  AuthorizeResponse,
  OAuthParams,
  OAuthResponse,
} from "../../types/oauth"
import { Cookies, useCookies } from "react-cookie"
import { store } from "../store/store"

interface JWTPayload {
  user_id: string
  family: string
  scopes: string[]
  exp: number
}

export const serviceApi = createApi({
  reducerPath: "serviceApi",
  refetchOnReconnect: true,
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BACKEND_URL,
    // credentials: "include",
    prepareHeaders: async (headers, { endpoint }) => {
      const cookies = new Cookies(null, { path: "/" })

      let jwt = cookies.get("auth_token")
      if (jwt) {
        const [, payload] = jwt.split(".")
        const data = JSON.parse(atob(payload)) as JWTPayload
        if (
          new Date(data.exp * 1000 + 60000) < new Date() &&
          !["tokenLogin", "emailLogin", "newUser"].includes(endpoint)
        ) {
          const old_refresh = cookies.get("refresh_token")

          try {
            const {
              payload: { auth_token, refresh_token },
            } = await store
              .dispatch(
                serviceApi.endpoints!.tokenLogin.initiate({
                  refresh_token: old_refresh,
                  app_id: import.meta.env.VITE_APP_ID,
                }),
              )
              .unwrap()
            cookies.set("auth_token", auth_token, {
              sameSite: "strict",
              secure: true,
            })
            cookies.set("refresh_token", refresh_token, {
              sameSite: "strict",
              secure: true,
            })
            jwt = auth_token
          } catch (e) {
            console.error("Error logging in with token", e)
            // cookies.remove("auth_token")
            // cookies.remove("refresh_token")
          }
        }
        headers.set("auth_token", jwt)
      }
      return headers
    },
  }),
  tagTypes: ["User", "Application", "OAuth"] as const,
  endpoints: (builder) => ({
    getUser: builder.query<BaseResponse<User>, void>({
      query: () => `/user`,
      providesTags: ["User" as const],
    }),
    newUser: builder.mutation<BaseResponse<User>, NewUserBody>({
      query: (body) => ({
        url: `/user`,
        method: "POST",
        body,
      }),
      invalidatesTags: [],
    }),
    updateUser: builder.mutation<BaseResponse<User>, Partial<NewUserBody>>({
      query: (body) => ({
        url: `/user`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["User" as const],
      transformResponse(baseQueryReturnValue: BaseResponse<User>, meta, arg) {
        return baseQueryReturnValue
      },
    }),
    deleteUser: builder.mutation<void, void>({
      query: (body) => ({
        url: `/user`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["User" as const],
    }),
    emailLogin: builder.mutation<BaseResponse<LoginResponse>, EmailLoginBody>({
      query: (body) => ({
        url: `/login/email`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["User" as const],
      transformResponse(
        baseQueryReturnValue: BaseResponse<LoginResponse>,
        meta,
        arg,
      ) {
        const cookies = new Cookies(null, { path: "/" })
        if (baseQueryReturnValue.payload.auth_token) {
          cookies.set("auth_token", baseQueryReturnValue.payload.auth_token, {
            sameSite: "strict",
            secure: true,
          })
          cookies.set(
            "refresh_token",
            baseQueryReturnValue.payload.refresh_token,
            {
              sameSite: "strict",
              secure: true,
            },
          )
        } else {
          cookies.remove("auth_token")
          cookies.remove("refresh_token")
        }

        return baseQueryReturnValue
      },
    }),
    tokenLogin: builder.mutation<BaseResponse<LoginResponse>, TokenLoginBody>({
      query: (body) => ({
        url: `/login/token`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["User" as const],
      transformResponse(
        baseQueryReturnValue: BaseResponse<LoginResponse>,
        meta,
        arg,
      ) {
        const cookies = new Cookies(null, { path: "/" })
        cookies.set("auth_token", baseQueryReturnValue.payload.auth_token, {
          sameSite: "strict",
          secure: true,
        })
        cookies.set(
          "refresh_token",
          baseQueryReturnValue.payload.refresh_token,
          {
            sameSite: "strict",
            secure: true,
          },
        )

        return baseQueryReturnValue
      },
    }),
    logout: builder.mutation<void, void>({
      query: () => ({
        url: `/logout`,
        method: "POST",
        headers: {
          refresh_token: new Cookies(null, { path: "/" }).get("refresh_token"),
        },
        body: {
          refresh_token: new Cookies(null, { path: "/" }).get("refresh_token"),
        },
      }),
      transformResponse(baseQueryReturnValue: void, meta, arg) {
        const cookies = new Cookies(null, { path: "/" })
        cookies.remove("auth_token")
        cookies.remove("refresh_token")
        return baseQueryReturnValue
      },
      invalidatesTags: ["User" as const],
    }),
    getApplications: builder.query<BaseResponse<Application[]>, void>({
      query: () => `/applications`,
      providesTags: ["Application" as const],
    }),
    getApplicationByID: builder.query<BaseResponse<Application>, string>({
      query: (id) => `/applications/${id}`,
      providesTags: ["Application" as const],
    }),
    newApplication: builder.mutation<
      BaseResponse<NewApplicationResponse>,
      NewApplicationBody
    >({
      query: (body) => ({
        url: `/applications`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Application" as const],
    }),
    deleteApplication: builder.mutation<void, string>({
      query: (app_id) => ({
        url: `/applications/${app_id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Application" as const],
    }),
    updateApplication: builder.mutation<
      Application,
      { app_id: string; body: Partial<UpdateApplicationBody> }
    >({
      query: ({ app_id, body }) => ({
        url: `/applications/${app_id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Application" as const],
    }),
    authorize: builder.mutation<BaseResponse<OAuthResponse>, OAuthParams>({
      query: (params) => ({
        url: `/oauth`,
        method: "GET",
        params: { ...params, scopes: params.scopes.join("+") },
      }),
      invalidatesTags: ["OAuth" as const],
    }),
    // authorize: builder.mutation<BaseResponse<AuthorizeResponse>, AuthorizeBody>(
    //   {
    //     query: (body) => ({
    //       url: `/oauth`,
    //       method: "POST",
    //       body,
    //     }),
    //     invalidatesTags: ["OAuth" as const],
    //   },
    // ),
    getLinkCode: builder.query<BaseResponse<LinkResponse>, void>({
      query: () => `/user/link`,
      providesTags: ["User" as const],
    }),
    linkAccount: builder.mutation<BaseResponse<void>, string>({
      query: (account_name) => ({
        url: `/user/link`,
        params: {
          account_name,
        },
        method: "POST",
      }),
      invalidatesTags: ["User" as const],
    }),
  }),
})

export const {
  useGetUserQuery,
  useNewUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useEmailLoginMutation,
  useTokenLoginMutation,
  useLogoutMutation,
  useGetApplicationsQuery,
  useGetApplicationByIDQuery,
  useNewApplicationMutation,
  useDeleteApplicationMutation,
  useUpdateApplicationMutation,
  // useGetOAuthMutation,
  useAuthorizeMutation,
  useGetLinkCodeQuery,
  useLinkAccountMutation,
} = serviceApi

export function useProfile() {
  const [cookies] = useCookies(["auth_token", "refresh_token"])
  const { data, isSuccess, isError, ...rest } = useGetUserQuery(undefined, {
    skip: !cookies.auth_token,
  })

  return {
    data: cookies.auth_token ? data : undefined,
    isSuccess: cookies.auth_token ? isSuccess : false,
    isError: cookies.auth_token ? isError : true,
    ...rest,
  }
}
