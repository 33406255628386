import React, { useCallback, useMemo } from "react"
import {
  useAuthorizeMutation,
  useGetApplicationByIDQuery,
} from "../../app/api/service"
import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material"
import LoadingButton from "@mui/lab/LoadingButton"
import { useSearchParams } from "react-router-dom"
import { OAuthScope } from "../../types/oauth"
import { ErrorOutlineTwoTone, LinkRounded } from "@mui/icons-material"
import { CutPaper } from "../../components/paper/CutPaper"
import { Application } from "../../types/application"

export function OAuthErrorArea(props: { message: string }) {
  return (
    <Grid item xs={12} lg={12}>
      <CutPaper>
        <Stack
          spacing={1}
          direction={"column"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Typography variant={"h5"}>Invalid Application</Typography>
          <ErrorOutlineTwoTone style={{ fontSize: 80 }} />
        </Stack>
      </CutPaper>
    </Grid>
  )
}

export function OAuth() {
  const [params] = useSearchParams()
  const { data: app, error: appError } = useGetApplicationByIDQuery(
    params.get("app_id")!,
    {
      skip: !params.get("app_id"),
    },
  )

  const scopes = useMemo(
    () =>
      params.get("scopes")
        ? (params.get("scopes")!.split(" ") as OAuthScope[])
        : [],
    [params],
  )

  if (appError || !params.get("app_id")) {
    return <OAuthErrorArea message={"Invalid Application"} />
  }

  return app ? <OAuthArea app={app.payload} scopes={scopes} /> : null
}

export function OAuthArea(props: { app: Application; scopes: OAuthScope[] }) {
  const { app, scopes } = props
  const [authorize, { isLoading }] = useAuthorizeMutation()
  const [params] = useSearchParams()

  const redirect_uri = useMemo(
    () => params.get("redirect_uri") || (app && app.callback_uris[0]),
    [params, app],
  )

  const callback = useCallback(() => {
    authorize({
      app_id: params.get("app_id")!,
      redirect_uri: params.get("redirect_uri")!,
      scopes: scopes,
      state: params.get("state"),
    })
      .unwrap()
      .then((result) => {
        const redirect_uri = result.payload.redirect_uri
        const redirect_params: { state?: string; code: string } = {
          code: result.payload.code,
        }
        if (params.get("state")) {
          redirect_params["state"] = params.get("state")!
        }

        window.location.href =
          redirect_uri + new URLSearchParams(redirect_params)
      })
      .catch((e) => console.log("Auth failure"))
  }, [authorize, params, scopes])

  return (
    <Grid item xs={12} lg={12}>
      <CutPaper>
        <Stack spacing={1} direction={"column"}>
          <Typography variant="h5">Authorize with {app.name}</Typography>
          <Divider />
          <Stack spacing={1} direction={"column"}>
            <List subheader={"Requested Permissions"} dense disablePadding>
              {scopes.map((s) => {
                let scopeEntry = s as string
                switch (s) {
                  case "GetRSIID":
                    scopeEntry = "View your RSI handle"
                    break
                  case "GetDiscordID":
                    scopeEntry = "View your Discord ID"
                    break
                }

                return (
                  <ListItem key={s}>
                    <ListItemText>{scopeEntry}</ListItemText>
                  </ListItem>
                )
              })}
            </List>
          </Stack>

          <Divider />

          <List dense disablePadding>
            <ListItem>
              <ListItemIcon>
                <LinkRounded />
              </ListItemIcon>
              <ListItemText>
                Once you are authorized, you will be redirected to{" "}
                {redirect_uri}
              </ListItemText>
            </ListItem>
          </List>

          <Divider />

          <LoadingButton onClick={callback} loading={isLoading} disabled={!app}>
            Authorize
          </LoadingButton>
        </Stack>
      </CutPaper>
    </Grid>
  )
}

export function OAuthSkeleton() {
  return (
    <Grid item xs={12} lg={12}>
      <CutPaper>
        <Stack spacing={1}>
          <Skeleton variant={"rectangular"} height={400} />
        </Stack>
      </CutPaper>
    </Grid>
  )
}
