import { useGetApplicationsQuery } from "../../app/api/service"
import React from "react"
import { Application, ApplicationStatus } from "../../types/application"
import { Link } from "react-router-dom"
import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material"
import { Cancel, CheckCircle, RemoveCircle } from "@mui/icons-material"
import { SiteLink } from "../../components/link/SiteLink"
import { CutPaper } from "../../components/paper/CutPaper"

function AppListItem(props: { app: Application }) {
  const { app } = props

  return (
    <>
      <SiteLink to={`/application/${app.id}`}>
        <ListItemButton>
          <ListItemIcon>
            {app.status === ApplicationStatus.Active ? (
              <CheckCircle color={"success"} />
            ) : (
              <Cancel color={"error"} />
            )}
          </ListItemIcon>
          {app.name} &nbsp;
          <Typography
            variant={"subtitle2"}
            display={"inline"}
            color={app.status === "Active" ? "success" : "error"}
          >
            {app.status}
          </Typography>
        </ListItemButton>
      </SiteLink>
    </>
  )
}

function AppListItemSkeleton() {
  return (
    <ListItem>
      <Skeleton sx={{ width: "100%" }} />
    </ListItem>
  )
}

export const otherApps: Application[] = [
  {
    callback_uris: ["https://sc-market.space/oauth/callback"],
    id: "asd",
    status: ApplicationStatus.Active,
    name: "One",
  },
  {
    callback_uris: ["https://sc-market.space/oauth/callback"],
    id: "dsa",
    status: ApplicationStatus.Inactive,
    name: "Two",
  },
]

export function AppListArea() {
  const { data: applications, isLoading } = useGetApplicationsQuery()

  return (
    <Grid item lg={7} xs={12}>
      <CutPaper>
        <Stack spacing={1}>
          <Typography variant={"h5"}>Applications</Typography>
          <Divider />

          <List>
            {applications ? (
              applications.payload.map((item) => (
                <AppListItem app={item} key={item.id} />
              ))
            ) : (
              <>
                <AppListItemSkeleton />
                <AppListItemSkeleton />
                <AppListItemSkeleton />
              </>
            )}
          </List>
        </Stack>
      </CutPaper>
    </Grid>
  )
}
