import { Link, LinkProps } from "react-router-dom"
import React from "react"

export function SiteLink(props: LinkProps) {
  return (
    <Link
      {...props}
      style={{ textDecoration: "none", color: "inherit", ...props.style }}
    />
  )
}
