import React, { useCallback, useState } from "react"
import { useUpdateUserMutation } from "../../app/api/service"
import { validateEmail } from "../../util/validation"
import { AccountDeleteConfirmationDialog } from "./AccountDeleteConfirmation"
import {
  Divider,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { Visibility, VisibilityOff } from "@mui/icons-material"
import LoadingButton from "@mui/lab/LoadingButton"
import { CutPaper } from "../../components/paper/CutPaper"
import { useAlert } from "../../components/alert/AlertProvider"
import { BaseResponse } from "../../types/user"

export function ManageProfileArea() {
  const [email, setEmail] = useState<string | null>(null)
  const [emailConfirm, setEmailConfirm] = useState<string | null>(null)
  const [password, setPassword] = useState("")
  const emailsMatch = email === emailConfirm || !emailConfirm
  const emailValid = !email || validateEmail(email)
  const [show, setShow] = React.useState(false)
  const issueAlert = useAlert()

  const [updateUser, { isLoading }] = useUpdateUserMutation()

  const updateEmail = useCallback(() => {
    if (!email) {
      setEmail("")
      return
    }

    if (!emailConfirm) {
      setEmailConfirm("")
      return
    }

    updateUser({
      email,
    })
      .unwrap()
      .catch((err: { status: number; data: BaseResponse<string> }) => {
        issueAlert({
          message: err.data.payload,
          severity: "error",
        })
      })
  }, [updateUser, email, emailConfirm])

  const updatePassword = useCallback(() => {
    updateUser({
      password,
    })
  }, [updateUser, password])

  return (
    <Grid item xs={12} lg={6}>
      <CutPaper>
        <Stack spacing={1} direction={"column"}>
          <Typography variant={"h5"}>Manage Account Details</Typography>
          <Divider />

          <FormControl>
            <FormLabel>Email Update</FormLabel>
          </FormControl>
          <TextField
            label={"New Email Address"}
            placeholder={"me@example.com"}
            onChange={(event) => setEmail(event.target.value.trim())}
            value={email || ""}
            type={"email"}
            error={!emailValid}
            helperText={!emailValid ? "Invalid address" : undefined}
          />
          <TextField
            label={"Confirm New Email Address"}
            placeholder={"me@example.com"}
            onChange={(event) => setEmailConfirm(event.target.value.trim())}
            value={emailConfirm || ""}
            error={!emailsMatch}
            helperText={
              emailsMatch ? "Confirm your email" : "Addresses must match"
            }
          />
          <LoadingButton
            onClick={updateEmail}
            variant={"contained"}
            loading={isLoading}
          >
            Update Email
          </LoadingButton>
          <Divider />

          <FormControl>
            <FormLabel>Password Update</FormLabel>
          </FormControl>
          <TextField
            label={"New Password"}
            type={show ? "text" : "password"}
            onChange={(event) => setPassword(event.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShow((s) => !s)}>
                    {show ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <LoadingButton
            onClick={updatePassword}
            variant={"contained"}
            loading={isLoading}
          >
            Update Password
          </LoadingButton>
          <Divider />
          <FormControl>
            <FormLabel>Account Deletion</FormLabel>
          </FormControl>
          <AccountDeleteConfirmationDialog />
        </Stack>
      </CutPaper>
    </Grid>
  )
}
