import React from "react"
import { Link } from "react-router-dom"
import { useProfile } from "../../app/api/service"
import { LogoutButton } from "../../features/auth/LogoutButton"
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material"
import { useAlert } from "../alert/AlertProvider"

export function DashboardNav() {
  return (
    <Grid item xs={12}>
      <Stack spacing={1} direction={"row"}>
        <Link to={"/profile"}>
          <Button variant={"text"}>Profile</Button>
        </Link>
        <Link to={"/applications"}>
          <Button variant={"text"}>Applications</Button>
        </Link>
      </Stack>
    </Grid>
  )
}
