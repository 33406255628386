import { createTheme, Theme } from "@mui/material"

export const makeCut = (radius: string, theme?: Theme) => ({
  clipPath: `polygon(${radius} 0, 100% 0, 100% calc(100% - ${radius}), calc(100% - ${radius}) 100%, 0 100%, 0 ${radius})`,
  // boxSizing: 'border-box',
  // filter: `drop-shadow( 0px  1px 0 ${theme.palette.outline.main}) drop-shadow( 0px -1px 0 ${theme.palette.outline.main}) drop-shadow( 1px  0px 0 ${theme.palette.outline.main}) drop-shadow(-1px  0px 0 ${theme.palette.outline.main})`
})

export const makeTopLeftCut = (radius: string, theme?: Theme) => ({
  clipPath: `polygon(${radius} 0, 100% 0, 100% 100%, 0 100%, 0 ${radius})`,
  // boxSizing: 'border-box',
  // filter: `drop-shadow( 0px  1px 0 ${theme.palette.outline.main}) drop-shadow( 0px -1px 0 ${theme.palette.outline.main}) drop-shadow( 1px  0px 0 ${theme.palette.outline.main}) drop-shadow(-1px  0px 0 ${theme.palette.outline.main})`
})

export const makeBottomRightCut = (radius: string, theme?: Theme) => ({
  clipPath: `polygon(100% 0, 100% calc(100% - ${radius}), calc(100% - ${radius}) 100%, 0 100%, 0 0)`,
  // boxSizing: 'border-box',
  // filter: `drop-shadow( 0px  1px 0 ${theme.palette.outline.main}) drop-shadow( 0px -1px 0 ${theme.palette.outline.main}) drop-shadow( 1px  0px 0 ${theme.palette.outline.main}) drop-shadow(-1px  0px 0 ${theme.palette.outline.main})`
})

export const makeBottomCut = (radius: string, theme?: Theme) => ({
  clipPath: `polygon(0 0, 100% 0, 100% calc(100% - ${radius}), calc(100% - ${radius}) 100%, ${radius} 100%, 0 calc(100% - ${radius}))`,
  // boxSizing: 'border-box',
  // filter: `drop-shadow( 0px  1px 0 ${theme.palette.outline.main}) drop-shadow( 0px -1px 0 ${theme.palette.outline.main}) drop-shadow( 1px  0px 0 ${theme.palette.outline.main}) drop-shadow(-1px  0px 0 ${theme.palette.outline.main})`
})

export const makeReverseCut = (radius: string, theme?: Theme) => ({
  clipPath: `polygon(${radius} 0, 100% 0, 100% calc(100% - ${radius}), calc(100% - ${radius}) 100%, 0 100%, 0 ${radius})`,
  boxSizing: "border-box" as const,
  filter: `drop-shadow( 0px  1px 0 ${theme?.palette?.background?.default}) drop-shadow( 0px -1px 0 ${theme?.palette?.background?.default}) drop-shadow( 1px  0px 0 ${theme?.palette?.background?.default}) drop-shadow(-1px  0px 0 ${theme?.palette?.background?.default})`,
})

declare module "@mui/material/styles" {
  interface TypeBackground {
    default: string
    paper: string
    mainBackground: string
  }
}

export const mainTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#FFF",
    },
    secondary: {
      // main: "#1cbe9f",
      main: "#FFF",
    },
    error: {
      main: "rgb(107,36,18)",
    },
    success: {
      main: "#039370",
    },
    background: {
      // default: "#000000",
      paper: "#00000088",
      mainBackground:
        "linear-gradient(150deg, rgba(3,82,116,.9) 0%, rgba(122,42,21,.9) 100%), url(https://grainy-gradients.vercel.app/noise.svg)",
    },
  },
  typography: {
    fontFamily: `'Electrolize', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue'`,
  },
  components: {
    MuiPaper: {
      defaultProps: {
        variant: "outlined",
      },
      styleOverrides: {
        root: {
          padding: 16,
        },
        outlined: {
          ...makeBottomCut("18px"),
          borderRadius: 0,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "filled",
      },
      styleOverrides: {
        root: {
          // ...makeCut("12px"),
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          ...makeTopLeftCut("12px"),
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          // ...makeReverseCut('12px'),
          borderRadius: 0,
        },
        text: {
          // ...makeReverseCut('12px'),
          ...makeTopLeftCut("12px"),
          borderRadius: 0,
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          ...makeCut("12px"),
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          clipPath: "none",
        },
      },
    },
  },
})
