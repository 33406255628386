import React, { useCallback, useState } from "react"
import {
  useGetLinkCodeQuery,
  useLinkAccountMutation,
} from "../../app/api/service"
import { isAlphaNumeric } from "../../util/validation"
import {
  Button,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { OpenInNewRounded } from "@mui/icons-material"
import LoadingButton from "@mui/lab/LoadingButton"
import { useAlert } from "../../components/alert/AlertProvider"
import { CutPaper } from "../../components/paper/CutPaper"
import { BaseResponse } from "../../types/user"

export function LinkAccount() {
  const [handle, setHandle] = useState<string>("")
  const issueAlert = useAlert()

  const [linkAccount, { isLoading, error }] = useLinkAccountMutation()
  const { data } = useGetLinkCodeQuery()

  const callback = useCallback(() => {
    linkAccount(handle)
      .unwrap()
      .then((result) => {
        issueAlert({
          message: "Verified profile!",
          severity: "success",
        })
      })
      .catch((err: { status: number; data: BaseResponse<string> }) => {
        let message =
          "An unexpected error occurred while verifying. Is the code present in your bio?"
        switch (err.data.status) {
          case 409:
            message = "This user is already registered"
            break
          case 500:
            message = "An invalid handle was provided"
            break
          case 206:
            message =
              "Failed to verify profile. Is the code present in your bio?"
            break
          case undefined:
            message =
              "Failed to verify profile. Is the code present in your bio?"
            break
        }
        issueAlert({
          message,
          severity: "error",
        })
      })
  }, [issueAlert, linkAccount, handle])

  return (
    <Grid item xs={12} lg={6}>
      <CutPaper>
        <Stack spacing={1} direction={"column"}>
          <Typography variant={"h5"}>Link RSI Account</Typography>
          <Divider />
          <Typography>
            In order to verify you own the below RSI profile, please click "Copy
            Code" below to open your profile page and add the following code to
            your user profile short bio, and enter your RSI handle (not
            monniker) above. You can remove this code once verification is
            complete. Your code is{" "}
            <Button
              style={{ display: "inline" }}
              color={"success"}
              size={"small"}
              onClick={() => {
                navigator.clipboard.writeText(
                  data?.payload.sentinel || "PLACEHOLDER",
                )
                issueAlert({ message: "Copied code to clipboard" })
              }}
            >
              {data?.payload.sentinel || "PLACEHOLDER"}
            </Button>
            . Click submit once the code is visible on your profile.
          </Typography>

          <TextField
            label={"Account Handle"}
            onChange={(event) =>
              setHandle(
                event.target.value
                  .replace("https://robertsspaceindustries.com/citizens/", "")
                  .trim(),
              )
            }
            value={handle}
            type={"text"}
            error={!isAlphaNumeric(handle)}
            helperText={
              !isAlphaNumeric(handle) || handle.length < 3
                ? "Invalid handle"
                : undefined
            }
          />
          <Button
            variant={"text"}
            color={"success"}
            endIcon={<OpenInNewRounded />}
            onClick={async () => {
              await navigator.clipboard.writeText(
                data?.payload.sentinel || "PLACEHOLDER",
              )
              issueAlert({ message: "Copied code to clipboard" })
              window.open(
                "https://robertsspaceindustries.com/account/profile",
                "_blank",
              )
            }}
          >
            Copy Code and Open Profile
          </Button>
          <LoadingButton
            onClick={callback}
            variant={"contained"}
            loading={isLoading}
            disabled={!handle || !isAlphaNumeric(handle)}
          >
            Link RSI Account
          </LoadingButton>
        </Stack>
      </CutPaper>
    </Grid>
  )
}
