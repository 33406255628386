import { NavBar } from "../nav/NavBar"
import {
  Box,
  Breakpoint,
  Container,
  CssBaseline,
  Grid,
  useTheme,
} from "@mui/material"
import React from "react"

export function PageLayout(props: {
  children: React.ReactNode
  maxWidth?: Breakpoint
}) {
  const theme = useTheme()

  return (
    <>
      <main
        style={{
          background: theme.palette.background.mainBackground,
          // filter: "contrast(170%) brightness(1000%)",
          height: "100vh",
          width: "100vw",
          overflowY: "scroll",
          paddingBottom: 32,
        }}
      >
        <NavBar />
        {/*<Box height={128} />*/}
        <Container maxWidth={props.maxWidth || "lg"}>
          <Grid container spacing={4} justifyContent={"center"}>
            {props.children}
          </Grid>
        </Container>
      </main>
    </>
  )
}
