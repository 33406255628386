import { NavBar } from "../../components/nav/NavBar"
import { useProfile } from "../../app/api/service"
import { ManageProfileArea } from "../../features/profile/ManageProfileArea"
import { CreateApplicationArea } from "../../features/applications/CreateApplication"
import React from "react"
import { AppListArea } from "../../features/applications/AppList"
import { Container, Grid } from "@mui/material"
import { PageLayout } from "../../components/layout/PageLayout"
import { Navigate } from "react-router-dom"
import { LinkAccount } from "../../features/profile/LinkAccount"
import { UserStatus } from "../../types/user"
import { DashboardNav } from "../../components/nav/DashboardNav"

export function ProfilePage() {
  const { data: user, isError } = useProfile()

  if (isError) {
    return <Navigate to={"/login"} />
  }

  return (
    <PageLayout>
      <DashboardNav />
      <ManageProfileArea />
      {user?.payload.status === UserStatus.WaitingValidation && <LinkAccount />}
    </PageLayout>
  )
}
