import React, { useMemo, useState } from "react"
import { Box, Button, IconButton, Stack, TextField } from "@mui/material"
import { Close, Remove } from "@mui/icons-material"
export function ApplicationURIEntry(props: {
  hideRemove?: boolean
  onRemove: () => void
  onUpdate: (v: string) => void
  value: string
}) {
  const { onRemove, onUpdate, value, hideRemove } = props

  const validURI = useMemo(() => {
    try {
      const url = new URL(value)
      return ["http:", "https:"].includes(url.protocol)
    } catch (e) {
      return false
    }
  }, [value])

  return (
    <>
      <Stack
        spacing={1}
        direction="row"
        justifyContent={"left"}
        alignItems={"center"}
      >
        <IconButton
          aria-label="Remove URI"
          onClick={onRemove}
          sx={{ height: 32, width: 32 }}
        >
          <Close />
        </IconButton>
        <TextField
          size={"small"}
          fullWidth
          placeholder={"https://example.com/oauth/callback"}
          onChange={(event) => onUpdate(event.target.value.trim())}
          value={value}
          error={!validURI}
          // helperText={validURI ? undefined : "Invalid URI"}
        />
      </Stack>
    </>
  )
}
