import React, { useMemo } from "react"
import {
  useGetApplicationByIDQuery,
  useGetApplicationsQuery,
} from "../../app/api/service"
import { useParams } from "react-router-dom"
import { PageLayout } from "../../components/layout/PageLayout"
import { UpdateApplicationArea } from "../../features/applications/UpdateApplication"
import { ViewApplicationArea } from "../../features/applications/ViewApplication"
import { DashboardNav } from "../../components/nav/DashboardNav"
import { PaperSkeleton } from "../../components/skeleton/PaperSkeleton"

export function ManageAppPage() {
  const params = useParams<{ id: string }>()
  const { data: app } = useGetApplicationByIDQuery(params.id || "")

  return (
    <PageLayout>
      <DashboardNav />
      {app ? (
        <>
          <ViewApplicationArea app={app.payload} />
          <UpdateApplicationArea app={app.payload} />
        </>
      ) : (
        <>
          <PaperSkeleton xs={12} lg={6} />
          <PaperSkeleton xs={12} lg={6} />
        </>
      )}
    </PageLayout>
  )
}
