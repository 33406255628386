import { useProfile } from "../../app/api/service"
import { Navigate } from "react-router-dom"
import React from "react"
import { PageLayout } from "../../components/layout/PageLayout"

export function LandingPage() {
  const { isSuccess } = useProfile()

  return <PageLayout>{isSuccess && <Navigate to={"/profile"} />}</PageLayout>
}
