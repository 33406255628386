export interface Application {
  id: string
  callback_uris: string[]
  status: ApplicationStatus
  name: string
}

export enum ApplicationStatus {
  Active = "Active",
  Inactive = "Inactive",
  Unknown = "Unknown",
}

export interface NewApplicationBody {
  callback_uris: string[]
  name: string
}

export interface NewApplicationResponse {
  id: string
  user_id: string
  secret: string
  callback_uris: string[]
  status: ApplicationStatus
}

export interface UpdateApplicationBody {
  callback_uris: string[]
  status: ApplicationStatus
  name: string
}
