import React, { useEffect } from "react"
import { SignupArea } from "../../features/auth/SignupArea"
import { LoginArea } from "../../features/auth/LoginArea"
import { PageLayout } from "../../components/layout/PageLayout"
import { useNavigate } from "react-router-dom"
import { useProfile } from "../../app/api/service"

export function LoginPage() {
  const { isSuccess } = useProfile()
  const navigate = useNavigate()
  useEffect(() => {
    if (isSuccess) {
      navigate("/profile")
    }
  }, [navigate, isSuccess])

  return (
    <PageLayout maxWidth={"md"}>
      <LoginArea />
    </PageLayout>
  )
}
