import { serviceApi } from "../api/service"
import { configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query"

export const store = configureStore({
  reducer: {
    [serviceApi.reducerPath]: serviceApi.reducer,
  },

  middleware: (gDM) => gDM().concat(serviceApi.middleware),
})

setupListeners(store.dispatch)
