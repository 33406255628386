import React, { useMemo, useState } from "react"
import { AlertColor } from "@mui/material/Alert/Alert"
import { Alert, Snackbar } from "@mui/material"

export interface AlertEntry {
  message: string
  severity?: AlertColor
  autoHideDuration?: number
}

export const AlertHookContext = React.createContext<
  | [
      { entries: Map<number, AlertEntry>; count: number },
      React.Dispatch<
        React.SetStateAction<{
          entries: Map<number, AlertEntry>
          count: number
        }>
      >,
    ]
  | null
>(null)

export const useAlert = () => {
  let val = React.useContext(AlertHookContext)
  if (val == null) {
    throw new Error("Cannot use useAlert outside of AlertHookContext")
  }

  const [alertEntries, setAlertEntries] = val

  function issueAlert(entry: AlertEntry) {
    alertEntries.entries.set(alertEntries.count, entry)
    setAlertEntries({
      entries: alertEntries.entries,
      count: alertEntries.count + 1,
    })
  }

  return issueAlert
}
const useAlertValues = () => {
  let val = React.useContext(AlertHookContext)
  if (val == null) {
    throw new Error("Cannot use useAlertValues outside of AlertHookContext")
  }
  return val
}

export function AlertSnackbar(props: { id: number; details: AlertEntry }) {
  const [open, setOpen] = useState(true)
  const [, setEntries] = useAlertValues()

  return (
    <Snackbar
      open={open}
      autoHideDuration={props.details.autoHideDuration || 3000}
      onClose={() => {
        setOpen(false)
        setEntries((alertEntries) => {
          alertEntries.entries.delete(props.id)
          return {
            entries: alertEntries.entries,
            count: alertEntries.count,
          }
        })
      }}
    >
      <Alert variant="filled" severity={props.details.severity || "success"}>
        {props.details.message}
      </Alert>
    </Snackbar>
  )
}

export function AlertProvider(props: { children: React.ReactNode }) {
  const [entries, setEntries] = useState({
    entries: new Map<number, AlertEntry>(),
    count: 0,
  })

  const items = useMemo(() => {
    const result = []

    for (const [id, details] of entries.entries) {
      result.push(<AlertSnackbar key={id} id={id} details={details} />)
    }
    return result
  }, [entries])

  return (
    <AlertHookContext.Provider value={[entries, setEntries]}>
      {items}
      {props.children}
    </AlertHookContext.Provider>
  )
}
