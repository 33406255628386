import { Application } from "../../types/application"
import {
  Button,
  Divider,
  Grid,
  List,
  ListItemButton,
  Stack,
  Typography,
} from "@mui/material"
import { ApplicationDeleteConfirmationDialog } from "./ApplicationDeleteConfirmation"
import React from "react"
import { CutPaper } from "../../components/paper/CutPaper"
import { useLocation } from "react-router-dom"
import { useAlert } from "../../components/alert/AlertProvider"

export function ViewApplicationArea(props: { app: Application }) {
  const { app } = props
  const location = useLocation()
  const [showSecret, setShowSecret] = React.useState(false)
  const issueAlert = useAlert()

  return (
    <Grid item xs={12} lg={6}>
      <CutPaper>
        <Stack spacing={1} direction={"column"}>
          <Typography variant={"h5"}>
            Manage Application{" "}
            <Typography variant={"subtitle1"} display={"inline"}>
              {app?.name}
            </Typography>
          </Typography>
          <Divider />
          <Typography variant={"subtitle1"} fontWeight={"bold"}>
            App Details
          </Typography>
          <Stack spacing={1} direction={"row"} alignItems={"center"}>
            <Typography variant={"subtitle2"} color={"text.secondary"}>
              App ID: {app.id}
            </Typography>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(app.id)
                issueAlert({ message: "Copied app ID to clipboard" })
              }}
            >
              Copy
            </Button>
          </Stack>
          {location.state && (
            <>
              <Stack spacing={1} direction={"row"} alignItems={"center"}>
                {!showSecret && (
                  <Button onClick={() => setShowSecret(true)} size={"small"}>
                    Show Secret
                  </Button>
                )}
                {showSecret ? (
                  <>
                    <Typography variant={"subtitle2"} color={"text.secondary"}>
                      App Secret: {location.state.secret}
                    </Typography>
                    <Button
                      onClick={() => {
                        navigator.clipboard.writeText(location.state.secret)
                        issueAlert({
                          message: "Copied app secret to clipboard",
                        })
                      }}
                    >
                      Copy
                    </Button>
                  </>
                ) : (
                  <Typography variant={"subtitle2"} color={"text.secondary"}>
                    Hidden
                  </Typography>
                )}
              </Stack>
            </>
          )}
          <Typography variant={"subtitle1"} fontWeight={"bold"}>
            Redirect URIs
          </Typography>
          <List>
            {(app?.callback_uris || []).map((url) => (
              <ListItemButton component={"a"} href={url} target={"_blank"}>
                {url}
              </ListItemButton>
            ))}
          </List>

          <Stack direction={"row"} spacing={1}>
            <ApplicationDeleteConfirmationDialog app={app!} />
          </Stack>
        </Stack>
      </CutPaper>
    </Grid>
  )
}
