import React from "react"
import { Grid, Typography, Link as MaterialLink, Stack } from "@mui/material"
import { PageLayout } from "../../components/layout/PageLayout"
import { Link } from "react-router-dom"

export function Error404Page() {
  return (
    <PageLayout>
      <Grid item xs={12}>
        <Stack spacing={1} direction={"column"}>
          <Typography variant={"h4"}>404 - Page not Found</Typography>
          <Typography variant={"subtitle1"}>
            <Link to={"/"} style={{ textDecoration: "none" }}>
              <MaterialLink underline={"hover"}>Return to home</MaterialLink>
            </Link>
          </Typography>
        </Stack>
      </Grid>
    </PageLayout>
  )
}
