import React, { useCallback, useState } from "react"
import { useEmailLoginMutation, useProfile } from "../../app/api/service"
import { validateEmail } from "../../util/validation"
import {
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { Visibility, VisibilityOff } from "@mui/icons-material"
import LoadingButton from "@mui/lab/LoadingButton"
import { useAlert } from "../../components/alert/AlertProvider"
import { CutPaper } from "../../components/paper/CutPaper"
import { Link } from "react-router-dom"
import { BaseResponse } from "../../types/user"

export function LoginArea() {
  const [email, setEmail] = useState<string | null>(null)
  const [password, setPassword] = useState("")
  const [show, setShow] = React.useState(false)
  const emailValid = !email || validateEmail(email)
  const [login, { isLoading }] = useEmailLoginMutation()
  const { isLoading: userIsLoading } = useProfile()
  const issueAlert = useAlert()

  const signUp = useCallback(() => {
    if (!email) {
      setEmail("")
      return
    }

    login({
      email,
      password,
      app_id: import.meta.env.VITE_APP_ID,
    })
      .unwrap()
      .catch((e: { status: number; data: BaseResponse<string> }) => {
        if ([403, 404].includes(e.data.status)) {
          issueAlert({
            message: "Invalid username or password",
            severity: "error",
          })
        } else {
          issueAlert({
            message: e.data.payload,
            severity: "error",
          })
        }
      })
  }, [issueAlert, email, login, password])

  return (
    <Grid item xs={12} lg={6}>
      <CutPaper>
        <Stack spacing={1} direction={"column"}>
          <Typography variant={"h5"}>Log In</Typography>
          <Divider />
          <TextField
            fullWidth
            label={"Email Address"}
            placeholder={"me@example.com"}
            onChange={(event) => setEmail(event.target.value.trim())}
            value={email || ""}
            type={"email"}
            error={!emailValid}
            helperText={emailValid ? undefined : "Invalid address"}
          />
          <TextField
            fullWidth
            label={"Password"}
            placeholder={"Password"}
            type={show ? "text" : "password"}
            onChange={(event) => setPassword(event.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShow((s) => !s)}>
                    {show ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <LoadingButton
            onClick={signUp}
            disabled={!emailValid || !password.length}
            loading={isLoading || userIsLoading}
          >
            Log In
          </LoadingButton>

          <Stack
            spacing={1}
            direction={"row"}
            sx={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography>
              Don't have an account?{" "}
              <Link to={"/signup"}>
                <Button variant={"text"}>Sign Up</Button>
              </Link>
            </Typography>
          </Stack>
        </Stack>
      </CutPaper>
    </Grid>
  )
}
