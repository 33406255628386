import React from "react"
import { Divider, Paper } from "@mui/material"

export function PaperWrapper(props: { children: React.ReactNode }) {
  return (
    <Paper sx={{ padding: 0.25, backgroundColor: "#00000022", border: "none" }}>
      <Paper
        sx={{ padding: 0.25, backgroundColor: "#66666622", border: "none" }}
      >
        {props.children}
      </Paper>
    </Paper>
  )
}

export function CutPaper(props: { children: React.ReactNode }) {
  return (
    <PaperWrapper>
      <Paper sx={{ backgroundColor: "#00000088", paddingBottom: 2 }}>
        {props.children}
        <Divider sx={{ marginTop: 4 }} />
      </Paper>
    </PaperWrapper>
  )
}
