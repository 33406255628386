import React from "react"
import { Link } from "react-router-dom"
import { useProfile } from "../../app/api/service"
import { LogoutButton } from "../../features/auth/LogoutButton"
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Stack,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material"

export function NavBar() {
  const { data: user } = useProfile()
  const theme = useTheme()

  return (
    <AppBar
      elevation={0}
      variant={"outlined"}
      sx={{
        clipPath: "none",
        position: "static",
        marginBottom: 4,
        padding: 1,
        background: "transparent",
        border: "none",
      }}
    >
      <Toolbar
        sx={{
          paddingRight: 2, // keep right padding when drawer closed
          paddingLeft: 2, // keep right padding when drawer closed
          // boxShadow: `0 3px 5px 3px ${theme.palette.primary.main}`,
          overflow: "visible",
          background: "transparent",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid #AAAACC66",
            paddingBottom: 1,
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Link
              to={"/"}
              style={{
                textDecoration: "none",
                color: theme.palette.primary.main,
              }}
            >
              <Typography
                variant={"h4"}
                sx={{
                  textTransform: "uppercase",
                  fontWeight: 600,
                  fontFamily: "Orbitron",
                }}
              >
                Citizen ID
              </Typography>
            </Link>
          </Box>
          {user ? (
            <Stack spacing={1} direction={"row"}>
              <Link to={"/profile"}>
                <Avatar
                  src={
                    "https://robertsspaceindustries.com/rsi/static/images/account/avatar_default_big.jpg"
                  }
                />
              </Link>
              <LogoutButton />
            </Stack>
          ) : (
            <Stack spacing={1} direction={"row"}>
              {/*<a*/}
              {/*  href={*/}
              {/*    "https://discord.com/api/oauth2/authorize?client_id=1170109958549934211&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fdiscord&response_type=code&scope=email%20identify"*/}
              {/*  }*/}
              {/*>*/}
              {/*  <Button variant={"outlined"}>Log In</Button>*/}
              {/*</a>*/}
              <Link to={"/login"}>
                <Button variant={"contained"}>Log In</Button>
              </Link>
              <Link to={"/signup"}>
                <Button variant={"contained"}>Sign Up</Button>
              </Link>
            </Stack>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  )
}
