import "./App.css"
import "./fonts.css"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { LandingPage } from "./pages/landing/LandingPage"
import { Error404Page } from "./pages/landing/Erro404Page"
import { SignupPage } from "./pages/landing/SignupPage"
import { ProfilePage } from "./pages/dashboard/ProfilePage"
import { ManageAppPage } from "./pages/application/ManageApp"
import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"
import { CssBaseline, ThemeProvider } from "@mui/material"
import { mainTheme } from "./app/hooks/theme"
import { OAuthAuthorize } from "./pages/oauth/OAuthAuthorize"
import { AlertProvider } from "./components/alert/AlertProvider"
import React from "react"
import { ApplicationsPage } from "./pages/application/ApplicationsPage"
import { LoginPage } from "./pages/landing/LoginPage"
import { CookiesProvider } from "react-cookie"

const router = createBrowserRouter([
  {
    path: "/signup",
    element: <SignupPage />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/profile",
    element: <ProfilePage />,
  },
  {
    path: "/applications",
    element: <ApplicationsPage />,
  },
  {
    path: "/application/:id",
    element: <ManageAppPage />,
  },
  {
    path: "/oauth/authorize",
    element: <OAuthAuthorize />,
  },
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/*",
    element: <Error404Page />,
  },
])

function App() {
  return (
    <ThemeProvider theme={mainTheme}>
      <CssBaseline />

      <CookiesProvider defaultSetOptions={{ path: "/" }}>
        <AlertProvider>
          <RouterProvider router={router} />
        </AlertProvider>
      </CookiesProvider>
    </ThemeProvider>
  )
}

export default App
