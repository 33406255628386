import React, { useCallback, useState } from "react"
import { useNewApplicationMutation } from "../../app/api/service"
import { ApplicationURIEntry } from "./ApplicationURIEntry"
import {
  Button,
  Divider,
  FormLabel,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { Add } from "@mui/icons-material"
import { useNavigate } from "react-router-dom"
import LoadingButton from "@mui/lab/LoadingButton"
import { useAlert } from "../../components/alert/AlertProvider"
import { CutPaper } from "../../components/paper/CutPaper"

export function CreateApplicationArea() {
  const [name, setName] = useState<string | null>(null)
  const [callbackURIs, setCallbackURIs] = useState<string[]>([""])

  const nameValid = !!name && name.length >= 3
  const urlsValid =
    callbackURIs.find((u) => {
      if (u.length === 0) {
        return true
      }

      try {
        new URL(u)
        return false
      } catch {
        return true
      }
    }) === undefined && callbackURIs.length > 0

  const [createApplication, { isLoading }] = useNewApplicationMutation()
  const navigate = useNavigate()

  const issueAlert = useAlert()

  const createApp = useCallback(() => {
    createApplication({
      callback_uris: callbackURIs,
      name: (name || "").trim(),
    })
      .unwrap()
      .then((result) => {
        navigate(`/application/${result.payload.id}`, {
          state: { id: result.payload.id, secret: result.payload.secret },
        })
      })
      .catch((e) =>
        issueAlert({
          message: "Failed to create app",
          severity: "error",
        }),
      )
  }, [issueAlert, name, createApplication, callbackURIs, navigate])

  return (
    <Grid item xs={12} lg={5}>
      <CutPaper>
        <Stack direction={"column"} spacing={1}>
          <Typography variant="h5">Create Application</Typography>
          <Divider />
          <TextField
            label={"Name"}
            onChange={(event) => setName(event.target.value)}
            value={name || ""}
            type={"text"}
            error={name !== null && !nameValid}
          />
          <FormLabel>Callback URIs</FormLabel>
          <Stack spacing={1} direction={"column"}>
            {callbackURIs.map((uri, index) => (
              <ApplicationURIEntry
                onRemove={() =>
                  setCallbackURIs((old) => {
                    const newURIs = [...old]
                    newURIs.splice(index, 1)
                    return newURIs
                  })
                }
                value={callbackURIs[index]}
                onUpdate={(newValue) =>
                  setCallbackURIs((old) => {
                    const newURIs = [...old]
                    newURIs[index] = newValue
                    return newURIs
                  })
                }
              />
            ))}

            {callbackURIs.length < 16 && (
              <Button
                startIcon={<Add />}
                aria-label="Add URI"
                onClick={() => setCallbackURIs((old) => [...old, ""])}
              >
                Add URL
              </Button>
            )}
          </Stack>
          <LoadingButton
            onClick={createApp}
            disabled={(name?.length || 0) < 3 || !nameValid || !urlsValid}
            loading={isLoading}
          >
            Create Application
          </LoadingButton>
        </Stack>
      </CutPaper>
    </Grid>
  )
}
