import { Grid, GridProps, Skeleton } from "@mui/material"
import { CutPaper } from "../paper/CutPaper"

export function PaperSkeleton(props: { height?: number } & GridProps) {
  const { height, ...gridProps } = props
  return (
    <Grid item {...gridProps}>
      <CutPaper>
        <Skeleton height={height || 400} variant={"rectangular"} />
      </CutPaper>
    </Grid>
  )
}
